import styles from "../../HomeStyles/atomic/MoreLiveShoppingBtn.module.css";
export default function GameShowsBtn() {
  return (
    <a
      className={styles.MoreLiveShoppingBtn}
      href="https://www.liveryvideo.com/gameshows-and-trivia/"
    >
      more game showas & trivia
    </a>
  );
}
