import styles from "../../HomeStyles/atomic/LearningBtn.module.css";
export default function LearningBtn() {
  return (
    <a
      className={styles.LearningBtn}
      href="https://www.liveryvideo.com/live-elearning-blueprint/"
    >
      more live e-learning
    </a>
  );
}
