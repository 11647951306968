import styles from "../styles/Header.module.css";
export default function HeaderButton() {
  return (
    <a
      className={styles.nav_btn}
      href="https://www.liveryvideo.com/get-started/"
    >
      get started
    </a>
  );
}
