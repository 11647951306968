import styles from "../../HomeStyles/atomic/LearningBtn.module.css";
export default function MoreSportsBtn() {
  return (
    <a
      className={styles.LearningBtn}
      href="https://www.liveryvideo.com/livery-usp-sports-betting/"
    >
      more sports & -sports
    </a>
  );
}
