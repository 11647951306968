import styles from "../styles/MobileMenu.module.css";
export default function HeaderButton (){
    return(
        <a
        className={styles.Mobile_btn}
        href="https://www.liveryvideo.com/get-started/"
      >
        GET STARTED
      </a>
    )
}